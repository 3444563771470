import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';
import {
	QuickReloadSubscriber,
	ReloadType,
} from '@confluence/quick-reload/entry-points/subscription';

import { LazyRendererContentStateWithSpotlight } from '../lazyLoadables';

import type {
	RendererContentState as RendererContentStateType,
	RendererContentStateVariables,
} from './__types__/RendererContentStateQuery';
import { GraphQLDateFormat } from './__types__/RendererContentStateQuery';
import { RendererContentStateQuery } from './RendererContentStateQuery.graphql';

type RendererContentStateProps = {
	contentId: string;
	isDraft: boolean;
	hasSeparator?: boolean;
};

export const RendererContentStateImpl = ({
	contentId,
	isDraft,
	hasSeparator,
}: RendererContentStateProps) => {
	const { data, refetch } = useQuery<RendererContentStateType, RendererContentStateVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		RendererContentStateQuery,
		{
			fetchPolicy: 'cache-and-network',
			variables: {
				contentId,
				isDraft, // page preview uses draft version
				format: GraphQLDateFormat.USER_FRIENDLY,
			},
		},
	);

	if (!data) {
		return null;
	}

	const lastUpdatedInfo = data?.singleContent?.contentStateLastUpdated?.value;
	const contentState = data?.singleContent?.contentState;

	return (
		<Fragment>
			{contentState && (
				<LazyRendererContentStateWithSpotlight
					contentId={contentId}
					contentState={contentState}
					lastUpdatedInfo={lastUpdatedInfo}
					isDraft={isDraft}
					hasSeparator={hasSeparator}
				/>
			)}
			<QuickReloadSubscriber name="content-state" types={[ReloadType.content]} reload={refetch} />
		</Fragment>
	);
};

export const RendererContentState = withTransparentErrorBoundary({
	attribution: Attribution.PAGE_EXTENSIONS,
})(RendererContentStateImpl);
