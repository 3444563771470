import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { Box, xcss, Flex } from '@atlaskit/primitives';

import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { expValEquals } from '@confluence/feature-experiments';

import type { RendererContentStatePlaceholderProps } from './types';

const loadingPlaceholderBaseStyles = xcss({
	cursor: 'pointer',
	minHeight: '32px',
	borderRadius: '3px',
	paddingRight: 'space.100',
});

const placeHolderBackroundColorStyles = xcss({
	backgroundColor: 'color.background.neutral',
});

const subtlePlaceholderBackgroundColorStyles = xcss({
	backgroundColor: 'utility.elevation.surface.current',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentStateNamePlaceholderWrapper = styled.div({
	fontWeight: token('font.weight.medium'),
	visibility: 'hidden',
});

const classicContentStateColorPlaceholderStyles = xcss({
	borderRadius: '12px',
	height: '12px',
	width: '12px',
	marginTop: 'space.0',
	marginBottom: 'space.0',
	marginLeft: 'space.100',
	marginRight: 'space.100',
	backgroundColor: 'color.background.neutral',
});

const subtleContentStateColorPlaceholderStyles = xcss({
	borderRadius: '10px',
	height: '10px',
	width: '10px',
	marginLeft: 'space.050',
	marginRight: 'space.075',
	backgroundColor: 'color.background.neutral',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledBorder = styled.div({
	borderLeft: `2px solid ${token('color.border.accent.gray')}`,
	display: 'inline-block',
	width: '1px',
	height: '16px',
	margin: `0 ${token('space.050')} 0 10px`,
	alignSelf: 'center',
});

const flexWrapperStyles = xcss({
	marginLeft: 'space.075',
	marginRight: 'space.075',
});

export const RendererContentStatePlaceholder = ({
	contentState,
	isDraft,
}: RendererContentStatePlaceholderProps) => {
	const isLivePage = useIsCurrentPageLive();

	const contentStateLoadingPlaceholderStyles = [
		loadingPlaceholderBaseStyles,
		isLivePage || expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test')
			? subtlePlaceholderBackgroundColorStyles
			: placeHolderBackroundColorStyles,
	];
	const contentStateColorPlaceholderStyles = [
		isLivePage || expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test')
			? subtleContentStateColorPlaceholderStyles
			: classicContentStateColorPlaceholderStyles,
	];

	return (
		<Flex xcss={flexWrapperStyles}>
			<Flex
				as="li"
				alignItems="center"
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
				xcss={contentStateLoadingPlaceholderStyles}
				testId={
					isLivePage || expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test')
						? 'subtle-renderer-content-state-placeholder'
						: 'renderer-content-state-placeholder'
				}
			>
				<Box
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
					xcss={contentStateColorPlaceholderStyles}
					testId={
						isLivePage || expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test')
							? 'subtle-renderer-content-state-color-placeholder'
							: 'renderer-content-state-color-placeholder'
					}
				/>
				<ContentStateNamePlaceholderWrapper>
					{contentState?.name}
				</ContentStateNamePlaceholderWrapper>
			</Flex>
			{!isDraft && !expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test') && (
				<StyledBorder data-testid="placeholder-vertical-line" />
			)}
		</Flex>
	);
};
